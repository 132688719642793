<template>
  <div class="about">
    <div class="bread">
      <i id="i" class="el-icon-arrow-left" @click="goback()"></i>
      图片直播
      <span class="refresh" @click="refHandle()"
        ><i class="el-icon-refresh-right"></i>&nbsp; 刷新</span
      >
    </div>
    <div class="content" ref="content">
      <div class="box" v-for="(item, index) in srcList" :key="index">
        <div class="imgbox">
          <el-image
            style="height: 100%"
            :src="item"
            :preview-src-list="srcList"
          >
          </el-image>
        </div>
      </div>
    </div>
    <div class="text" v-if="loadShow">— 到底了 —</div>
    <div class="bg3"></div>
  </div>
</template>
<script>
import { getImage } from "@/views/API/image.js";
import { Loading } from "element-ui";
export default {
  data() {
    return {
      imgUrl: "http://www.gyycbj.com",
      loadShow: true,
      allList: [],
      srcList: [], //图片列表
      isReachBottom: false,
      reachBottomDistance: 100,
      scrollHeight: 0,
      offsetHeight: 0,
    };
  },
  created() {
    this.getAllImgaeHandle();
  },
  mounted() {
    // 页面加载完成后  将高度存储起来
    let dom = document.querySelector(".about");
    this.scrollHeight = dom.scrollHeight;
    this.offsetHeight = Math.ceil(dom.getBoundingClientRect().height);
  },
  methods: {
    // 页面触底去全数组拿取数据
    getImagedata() {
      if (this.allList.length <= 10) {
        this.srcList.push(...this.allList.splice(1))
        return this.loadShow = true
      };
      this.srcList.push(...this.allList.splice(1,10))
    },
    onScroll(e) {
      console.log(scrollTop);
      let scrollTop = e.target.scrollTop;
      let currentHeight =
        scrollTop + this.offsetHeight + this.reachBottomDistance;

      if (currentHeight < this.scrollHeight && this.isReachBottom) {
        this.isReachBottom = false;
      }
      if (this.isReachBottom) {
        return;
      }
      if (currentHeight >= this.scrollHeight) {
        this.isReachBottom = true;
        console.log('页面触底');
        this.getImagedata()
      }
    },
    // 获取全部图片
    async getAllImgaeHandle() {
      const loading = Loading.service({
        lock: true,
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const res = await getImage();
      if (res.data.code == "0000") {
        this.srcList = res.data.data.map((item) => {
          return this.imgUrl + item;
        });
    
      } else {
        this.$message.error("获取图片信息失败");
      }
      loading.close();
    },
    goback() {
      this.$router.go(-1);
    },
    refHandle() {
      this.getAllImgaeHandle();
    },
  },
};
</script>
<style scoped lang="scss">
.about {
  color: #fff;
  padding-bottom: 120px;
  box-sizing: border-box;
  background: url("../../assets/image2/background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  position: relative;
  ::v-deep .el-image img {
    height: 100%;
    width: auto;
  }
  .text {
    text-align: center;
    padding-top: 20px;
    color: #ffaaaa;
    font-size: 14px;
  }
  .bread {
    height: 50px;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    position: relative;
    #i {
      position: absolute;
      left: 15px;
      top: 17px;
    }
    .refresh {
      position: absolute;
      right: 15px;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .content {
    z-index: 999;
    padding: 0 10px;
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    .box {
      flex-basis: 50%;
      box-sizing: border-box;
      padding: 5px;
      height: 110px;
      .imgbox {
        height: 100%;
        background-color: #fff;
        text-align: center;
      }
    }
  }
  .bg3 {
    position: absolute;
    background: url("../../assets/image2/BG-3.png") no-repeat;
    background-size: 100% 100%;
    bottom: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 120px;
  }
}
</style>
